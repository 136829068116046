import Camera from './Camera.mp3';
import Winner from './Winner.mp3';
import Drawer from './Drawer.mp3';
import Failure from './Failure.mp3';
import PlayerJoined from './PlayerJoined.mp3';
import PlayerLeft from './PlayerLeft.mp3';
import WarningTimer from './WarningTimer.mp3';

const audios = {
    'Camera': new Audio(Camera),
    'Winner': new Audio(Winner),
    'Drawer': new Audio(Drawer),
    'Failure': new Audio(Failure),
    'PlayerJoined': new Audio(PlayerJoined),
    'PlayerLeft': new Audio(PlayerLeft),
    'WarningTimer': new Audio(WarningTimer),
};

Object.values(audios).forEach(a => a.volume = .5);

let currentlyPlaying = null;

const play = (name) => {
    if (currentlyPlaying) {
        audios[currentlyPlaying].pause();
    }
    audios[name].play();
    currentlyPlaying = name;
};

const pause = (name) => {
    currentlyPlaying = null;
    audios[name].pause();
};

export default {
    pause,
    play
};