export type DrawEvent = {
    type: number; // 0: onMouseDown, 1: onMouseUp, 2: onMouseMove
    tool: DrawingCanvasTool;
    color: number;
    size: number;
    x: number;
    y: number;
};

// Whenever you add colors, add the RGB mapping at the exact index in the colorsRgb array below
export const colors = [
    'black',
    'grey',
    'white',
    'red',
    'orange',
    'yellow',
    'green',
    'blue',
    'purple',
    'pink'
];

// RGB object mappings for every color for the Fill tool
export const colorsRgb = [
    { r: 0, g: 0, b: 0, },
    { r: 128, g: 128, b: 128, },
    { r: 255, g: 255, b: 255, },
    { r: 255, g: 0, b: 0, },
    { r: 255, g: 165, b: 0, },
    { r: 255, g: 255, b: 0, },
    { r: 0, g: 128, b: 0, },
    { r: 0, g: 0, b: 255, },
    { r: 128, g: 0, b: 128, },
    { r: 255, g: 192, b: 203, }
];

export const sizes = [
    1,
    5,
    10,
    15,
    25
];

export enum DrawingCanvasTool {
    Brush = 0,
    Eraser = 1,
    Fill = 2
}