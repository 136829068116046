import styles from "./PlayerList.module.scss";
import { Player } from "../../types/Player"

export const PlayerList = (props: { players: Player[], currentProfile?: string }) => {

    return (
        <div className={`${styles.players} players`}>
            {props.players.map((player: any) => <div key={player.name + player.score} className={`${player.answered ? styles.answered : ""}`}>
                <strong>{player.name} {props?.currentProfile === player.name && "(You)"}</strong>
                <p>Score: {player.score}</p>
            </div>)}
        </div>
    )
}