import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDiscord } from '@fortawesome/free-brands-svg-icons';
import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import styles from './HomePage.module.scss';

export const HomePage = () => {
    const [customName, setCustomName] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        let name = localStorage.getItem("customName");
        if (name) {
            setCustomName(name);
        }
    }, [])

    useEffect(() => {
        localStorage.setItem("customName", customName);
    }, [customName])

    const setCustomNameInLocalStorage = () => {
        localStorage.setItem("customName", customName);
    }

    const NavigateToRandomGame = () => {
        setCustomNameInLocalStorage();
        navigate("/drawAndGuessGame/random");
    }

    const NavigateToCreatePrivateGame = () => {
        setCustomNameInLocalStorage();
        navigate("/drawAndGuessGame/private");
    }

    return (
        <>
            <h1 className={styles.title}>
                <img src='/images/header.png' alt='Draw For Fun' />
            </h1>
            <div className={styles.container}>
                <input style={{ margin: "4px" }} type="text" value={customName} onChange={(e) => setCustomName(e.target.value)} placeholder="Enter a display name..." maxLength={16} />
                <button type='submit' className={styles.play} style={{ margin: "4px" }} onClick={NavigateToRandomGame}>Play</button>
                <button type='submit' className={styles.private} style={{ margin: "4px" }} onClick={NavigateToCreatePrivateGame}>Create Private Game</button>
            </div>
            <a className={styles.discord} href='http://discord.drawforfun.io/'><FontAwesomeIcon icon={faDiscord} /></a>
            <br style={{ marginBottom: "64px" }} />
            <NavLink to={"/legal"} style={{ color: "white", fontSize: "1rem" }}>Legal</NavLink>
        </>
    )
}