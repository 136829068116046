import styles from "./Results.module.scss";
import { Player } from "../../types/Player";
import { useCountdown } from "../../hooks/useCountdown";
import { useEffect } from "react";
import audio from "../../sounds/globalAudio";



// This component is for the results pop-up at the end of each round which indicates winners and accumalitve points of each user for the session.

export const Results = (props: any) => {
    const secondsLeft = useCountdown(props.gameState?.nextRoundStart);

    useEffect(() => {
        const localPlayer = localStorage.getItem("customName");
        props.gameState.players.forEach((p: Player) => {
            if (p.name === localPlayer && p.scoreThisRound > 0) {
                audio.play('Winner');
            } else if (p.name === localPlayer) {
                audio.play("Failure");
            };
        });
    }, []);

    return (
        <div className={styles.container}>
            <h2 className={styles.currentDrawer}><span>{props.gameState?.currentDrawer}</span> drew the word</h2>
            <h1 className={styles.word}>{props.gameState?.word}</h1>
            <div className={styles.players}>
                {props.gameState.players.sort((p1: Player, p2: Player) => p1.scoreThisRound < p2.scoreThisRound).map((p: Player) => 
                <div className={`${styles.player} ${p.scoreThisRound > 0 && styles.stroke}`} style={{ color: p.scoreThisRound > 0 ? '#00F700' : '#F70000' }}>
                    <div className={styles.name}>{p.name}</div>
                    <div className={styles.score}>{p.scoreThisRound > 0 ? ` +${p.scoreThisRound}` : '0'}</div>
                </div>)}
            </div>
            <div className={styles.counter}>Next round in {secondsLeft} seconds</div>
        </div>
    )
}
