import React, { useState } from 'react';
import { ChatMessage } from '../../types/ChatMessage';
import styles from "./ChatBox.module.scss";

export const ChatBox = (props: { chatMessages: ChatMessage[], sendMessage: (message: string) => void }) => {
    const [chatBox, setChatBox] = useState<string>("");

    const sendChatMessage = () => {
        if (chatBox.length === 0) return;
        props.sendMessage(chatBox);
        setChatBox("");
    }

    return (
        <div className={`${styles.chatBox} chatBox`} style={{ border: "1px solid black", borderRadius: "4px" }}>
            <div className={styles.chatMessages} id={"gameChatBox"}>
                {props.chatMessages.map((message: any) => <div style={{ color: message?.color ?? (message.name == "Game" ? "grey" : "black") }} key={message.name + message.message + message?.sentTime}>
                    {message.name != "Game" && <span>{message.name}: </span>}
                    <span style={{ color: message?.color ?? (message.name == "Game" ? "grey" : "black"), fontWeight: message.name == "Game" ? 600 : 400 }}>{message.message}</span>
                </div>)}
            </div>
            <form className={styles.chatInput} onSubmit={(e) => {
                e.preventDefault();
                sendChatMessage();
            }}>
                <input type="text" value={chatBox} onChange={(e: any) => setChatBox(e.target.value)} placeholder={"Enter a chat message..."} />
                <button>Send</button>
            </form>
        </div>
    )
}