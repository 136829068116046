import { useEffect, useState } from 'react';

export const useCountdown = (targetDate?: any) => {
    const countDownDate = new Date(targetDate).getTime();

    const [countDown, setCountDown] = useState(
        countDownDate - new Date().getTime()
    );

    useEffect(() => {
        const interval = setInterval(() => {
            setCountDown(countDownDate - new Date().getTime());
        }, 1000);

        return () => clearInterval(interval);
    }, [countDownDate]);

    return getSecondsLeft(countDown);
};

const getSecondsLeft = (countDown: any) => {
    // calculate time left
    const seconds = Math.floor((countDown) / 1000);
    return seconds;
};