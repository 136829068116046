import { HttpTransportType, HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { ChatMessage } from '../../types/ChatMessage';
import { DrawAndGuessGameState } from '../../types/DrawAndGuessGameState';
import { DrawEvent } from '../../types/DrawEvent';
import { Player } from '../../types/Player';
import { ChatBox } from '../ChatBox/ChatBox';
import { DrawingCanvas } from '../DrawingCanvas/DrawingCanvas';
import { PlayerList } from '../PlayerList/PlayerList';
import { Countdown } from './Countdown';
import audio from '../../sounds/globalAudio';

import styles from "./DrawAndGuessGamePage.module.scss";

export const DrawAndGuessGamePage = () => {
    const params = useParams();
    const navigate = useNavigate();
    const [profile, setProfile] = useState<string>("");
    const [connection, setConnection] = useState<HubConnection>();
    const [gameState, setGameState] = useState<DrawAndGuessGameState | any>();
    const [wordToDraw, setWordToDraw] = useState<string>();
    const [chatMessages, setChatMessages] = useState<ChatMessage[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    //TODO: Find a better way to do this? (keep scroll to bottom, optimize after restructuring componentry)
    useEffect(() => {
        var element = document.getElementById("gameChatBox");
        if (!element) return;
        element.scrollTop = element?.scrollHeight;
    }, [chatMessages])

    useEffect(() => {
        let socket = new HubConnectionBuilder()
            .withUrl(process.env.REACT_APP_ENV === "LOCAL" ? "https://localhost:7231/drawAndGuessGame" : "https://drawforfungameserver.herokuapp.com/drawAndGuessGame", {
                skipNegotiation: true,
                transport: HttpTransportType.WebSockets,
                withCredentials: false
            })
            .withAutomaticReconnect()
            .build();

        setProfile(localStorage.getItem("customName") ?? "");
        setConnection(socket);
    }, []);

    useEffect(() => {
        if (connection) {
            connection.start()
                .then(() => {
                    console.log("Connected");
                    initializeConnectionListeners();
                    connection.invoke("ConnectMessage", params.lobbyId, profile);
                })
                .catch((e: any) => console.log('Connection failed: ', e));
        }

        return () => {
            connection?.stop();
        }
    }, [connection]);

    const initializeConnectionListeners = () => {
        if (!connection) return;

        connection.on("ProfileUpdate", async (newName: string) => {
            localStorage.setItem("customName", newName);
            setProfile(newName);
        });

        connection.on("AllGameState", async (data: DrawAndGuessGameState) => {
            navigate(`/drawAndGuessGame/${data.lobbyId}`, { replace: true });
            if (data.currentDrawer == null && data.wordLength === 0) {
                setChatMessages((prevMessages: any) => [...prevMessages, { name: "Game", message: `The game will start when there are at least two players.`, sentTime: Date.now() }]);
            } else {
                setChatMessages((prevMessages: any) => [...prevMessages, { name: "Game", message: `${data.currentDrawer} is drawing a ${data.wordLength} letter long word.`, sentTime: Date.now() }]);
            }
            setGameState(data);
            setLoading(false);
        });

        connection.on("RoundEnd", async (data: DrawAndGuessGameState) => {
            setGameState((prevGameState: DrawAndGuessGameState) => ({ ...prevGameState, ...data }));
        });

        connection.on("Error", (data: any) => {
            console.error("Error", data);
            alert(data);
            navigate("/");
        });

        connection.on("ReceiveChatMessage", (message: ChatMessage) => {
            setChatMessages((prevMessages: any) => [...prevMessages, { ...message, sentTime: Date.now() }]);
        });

        connection.on("PlayerJoined", async (player: Player) => {
            audio.play('PlayerJoined');
            setChatMessages((prevMessages: any) => [...prevMessages, { name: "Game", message: `${player.name} has joined`, sentTime: Date.now(), color: "green" }]);
            setGameState((prevGameState: DrawAndGuessGameState) => ({
                ...prevGameState,
                players: [...prevGameState.players, player]
            }));
        });

        connection.on("PlayerLeft", async (playerName: string) => {
            audio.play('PlayerLeft');
            setChatMessages((prevMessages: any) => [...prevMessages, { name: "Game", message: `${playerName} has left`, sentTime: Date.now(), color: "red" }]);
            setGameState((prevGameState: DrawAndGuessGameState) => ({
                ...prevGameState,
                players: prevGameState.players.filter((player: any) => player.name !== playerName)
            }));
        });

        connection.on("NewGameRound", async (data: { currentDrawer: string, turnEnd: string, wordLength: number, youDraw: boolean }) => {
            if (data.youDraw) {
                audio.play('Drawer');
            }

            if (data.currentDrawer == null && data.wordLength == 0) {
                setChatMessages((prevMessages: any) => [...prevMessages, { name: "Game", message: `The game is paused until there are at least two players.`, sentTime: Date.now() }]);
            } else if (!data.youDraw) {
                setChatMessages((prevMessages: any) => [...prevMessages, { name: "Game", message: `New round! ${data.currentDrawer === profile ? "You are" : `${data.currentDrawer} is`} now drawing a ${data.wordLength} letter long word.`, sentTime: Date.now(), color: "green" }]);
            }

            setGameState((prevGameState: DrawAndGuessGameState) => ({
                ...prevGameState,
                currentDrawer: data.currentDrawer,
                turnEnd: data.turnEnd,
                wordLength: data.wordLength,
                players: prevGameState.players.map(p => ({ ...p, answered: false })),
                youDraw: data.youDraw,
                nextRoundStart: null,
                word: null
            }));
        });

        connection.on("WordToDraw", async (word: string) => {
            setWordToDraw(word);
            setChatMessages((prevMessages: any) => [...prevMessages, { name: "Game", message: `It is your turn to draw the word "${word}"`, sentTime: Date.now(), color: "green" }]);
        });

        connection.on("CorrectGuess", async (playerName: string) => {
            setChatMessages((prevMessages: any) => [...prevMessages, { name: "Game", message: `${playerName === profile ? "You" : playerName} guessed the word correctly!`, sentTime: Date.now(), color: "green" }]);
            setGameState((prevGameState: DrawAndGuessGameState) => ({
                ...prevGameState,
                players: prevGameState.players.map(p => p.name === playerName ? { ...p, answered: true } : p)
            }));
        });
    }

    const sendMessage = (message: string) => {
        if (message.toLowerCase() === "clear()") {
            setChatMessages([]);
        } else {
            connection?.invoke("SendMessageToGame", message);
        }
    }

    const sendDrawEvent = (drawEvent: DrawEvent) => {
        if (gameState?.youDraw) {
            connection?.invoke("SendDrawEvents", [drawEvent]);
        }
    }

    const blankWordText = () => {
        let text = "";
        for (let i = 0; i < gameState.wordLength; i++) {
            text += "_";
        }
        return text;
    }

    if (loading) {
        return <h1 style={{ fontSize: "64px" }}>Loading</h1>
    }

    // Logs for debugging
    let debugGameStage = null;
    if (gameState?.nextRoundStart != null && gameState?.word) {
        debugGameStage = "Round Results";
    } else if (!gameState.currentDrawer) {
        debugGameStage = "Game paused";
    } else {
        debugGameStage = "Playing";
    }
    console.log("Game Stage: ", debugGameStage)
    // End Logs for debugging

    return (
        <div className={styles.container}>
            {gameState ?
                <>
                    <div className={styles.heading}>
                        <h2>{/* Who knows what to put here... */}</h2>
                        <h1 className={styles.waiting}>{!gameState.currentDrawer ? "Waiting for players..." : (gameState.currentDrawer == profile ? wordToDraw : blankWordText())}</h1>
                        {(gameState.currentDrawer && !(gameState?.nextRoundStart != null && gameState?.word)) && <Countdown date={gameState.turnEnd} />}
                    </div>

                    <div className={styles.gameContent}>
                        <PlayerList players={gameState.players} currentProfile={profile} />

                        <DrawingCanvas sendDrawEvent={sendDrawEvent} gameState={gameState} connection={connection} />

                        <ChatBox chatMessages={chatMessages} sendMessage={sendMessage} />
                    </div>
                </>
                : <div>Connecting to game</div>}
        </div>
    )
}
