import React, { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import { DrawAndGuessGamePage } from './components/DrawAndGuessGamePage/DrawAndGuessGamePage';
import { HomePage } from './components/HomePage';
import ReactGA from 'react-ga4';
import { Legal } from './components/Legal';

const App = () => {
  const location = useLocation();

  useEffect(() => {
    document.title = location.pathname;
    ReactGA.send({ hitType: "pageview", page: location.pathname });
    document.title = "DrawForFun.io";
  }, [location.pathname]);

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/drawAndGuessGame/:lobbyId" element={<DrawAndGuessGamePage />} />
        <Route path="/legal" element={<Legal />} />
      </Routes>
    </div>
  );
}

export default App;
