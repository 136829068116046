import { useEffect, useRef, useState } from 'react';
import { DrawAndGuessGameState } from '../../types/DrawAndGuessGameState';
import { colors, DrawEvent, DrawingCanvasTool } from '../../types/DrawEvent';
import { Results } from '../Results/Results';
import styles from "./DrawingCanvas.module.scss";
import { FillTool } from './FillTool';

export const DrawingCanvas = (props: { sendDrawEvent: (e: DrawEvent) => void, gameState: DrawAndGuessGameState | any, connection?: any }) => {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const [isDrawing, setIsDrawing] = useState<boolean>(false);
    const [color, setColor] = useState<number>(0);
    const [size, setSize] = useState<number>(5);
    const [scaled, setScaled] = useState<boolean>(false);
    const [selectedTool, setSelectedTool] = useState<DrawingCanvasTool>(DrawingCanvasTool.Brush);
    const previousGameState = useRef<DrawAndGuessGameState>(props.gameState);
    // const [unsentDrawEvents, setUnsentDrawEvents] = useState<DrawEvent[]>([]); // Use for batching later
    const clampNumber = (num: number, a: number, b: number) => Math.max(Math.min(num, Math.max(a, b)), Math.min(a, b));

    useEffect(() => {
        previousGameState.current = props.gameState;

        window.addEventListener('wheel', (e: any) => {
            if (e.wheelDeltaY > 0) {
                setSize((prevSize: number) => clampNumber(prevSize + 1, 1, 25));
            } else if (e.wheelDeltaY < 0) {
                setSize((prevSize: number) => clampNumber(prevSize - 1, 1, 25));
            }
        });

        window.addEventListener('keydown', function (e) {
            switch (e.key) {
                case 'b':
                    setBrushMode();
                    break;
                case 'e':
                    setEraserMode();
                    break;
                case 'f':
                    setFillMode();
                    break;
                default:
                    break;
            }
        }, false);
    }, []);

    useEffect(() => {
        if (props.connection) {
            props.connection.on("ReceiveDrawEvents", async (events: DrawEvent[]) => {
                events.forEach(e => processEvent(e));
            });

            props.connection.invoke("LoadDrawEvents");

            if (!canvasRef.current || scaled) return;

            const context = canvasRef.current.getContext("2d");
            if (!context) return;
            // Commented out upscaling fidelity until the fill algo bug is fixed based on scale
            // canvasRef.current.width = 728 * 2;
            // canvasRef.current.height = 600 * 2;
            // context.scale(2, 2);

            // Prevent scrolling when touching the canvas
            document.body.addEventListener("touchstart", function (e) {
                if (e.target == canvasRef.current) {
                    e.preventDefault();
                }
            }, { passive: false });
            document.body.addEventListener("touchend", function (e) {
                if (e.target == canvasRef.current) {
                    e.preventDefault();
                }
            }, { passive: false });
            document.body.addEventListener("touchmove", function (e) {
                if (e.target == canvasRef.current) {
                    e.preventDefault();
                }
            }, { passive: false });
            //

            context.lineCap = "round";
            document.onselectstart = () => false;
            setScaled(true);
        }
    }, [props.connection]);

    const disableDrawingWhenMouseUpOutsideOfCanvas = () => {
        if (!isDrawing && !props.gameState?.youDraw) return;
        setIsDrawing(false);
    }

    // This is for brush size.
    useEffect(() => {
        if (!canvasRef.current) return;
        const context = canvasRef.current.getContext("2d");
        if (!context) return;
        context.lineWidth = size;
    }, [size]);

    useEffect(() => {
        if (previousGameState.current && previousGameState.current.currentDrawer != props.gameState?.currentDrawer) {
            // Reset drawing colors if new drawer
            if (!canvasRef.current) return;
            const context = canvasRef.current.getContext("2d");
            if (!context) return;
            context.strokeStyle = colors[0];
            context.globalCompositeOperation = 'source-over';
            setColor(0);
        }

        previousGameState.current = props.gameState;
    }, [props.gameState]);

    const processEvent = (e: DrawEvent) => {
        if (!canvasRef.current) return;
        const context = canvasRef.current.getContext("2d");
        if (!context) return;
        context.strokeStyle = colors[e.color];
        context.fillStyle = colors[e.color];
        context.lineWidth = e.size;

        if (e.tool === DrawingCanvasTool.Eraser) {
            context.globalCompositeOperation = 'destination-out';
        } else if (e.tool === DrawingCanvasTool.Fill && e.type !== 3) {
            FillTool.fill(Math.round(e.x), Math.round(e.y), 100, context);
            return;
        } else {
            context.globalCompositeOperation = 'source-over';
        }

        if (e.type == 0) {
            context?.moveTo(e.x, e.y);
            context?.beginPath();
        } else if (e.type == 1) {
            context?.closePath();
            context?.moveTo(e.x, e.y);
            // setIsDrawing(false); Not needed, keeping in case of regression
        } else if (e.type == 2) {
            context?.lineTo(e.x, e.y);
            context?.stroke();
        } else if (e.type == 3 && canvasRef.current != null) {
            context?.closePath();
            context?.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
            // setIsDrawing(false); Not needed, keeping in case of regression
        }
    }

    const onMouseDown = (e: any) => {
        e.preventDefault();
        if (!props.gameState?.youDraw) return;

        if (!canvasRef.current) return;
        const context = canvasRef.current.getContext("2d");
        if (!context) return;

        let bounds = canvasRef.current.getBoundingClientRect();
        let mouseX = e.pageX - bounds.left - scrollX;
        let mouseY = e.pageY - bounds.top - scrollY;
        if (e.touches) {
            mouseX = e.touches[0].pageX - bounds.left - scrollX;
            mouseY = e.touches[0].pageY - bounds.top - scrollY;
        }
        mouseX /= bounds.width;
        mouseY /= bounds.height;
        mouseX *= canvasRef.current.width;
        mouseY *= canvasRef.current.height;

        const drawEvent: DrawEvent = {
            type: 0,
            tool: selectedTool,
            color: color,
            size: size,
            x: mouseX,
            y: mouseY
        }

        if (selectedTool === DrawingCanvasTool.Fill) {
            context.fillStyle = colors[color];
            FillTool.fill(Math.round(mouseX), Math.round(mouseY), 100, context);
            props.sendDrawEvent(drawEvent);
            return;
        } else {
            context?.beginPath();
            context?.moveTo(mouseX, mouseY);
            props.sendDrawEvent(drawEvent);
        }

        setIsDrawing(true);
    }

    const onMouseUp = (e: any) => {
        e.preventDefault();
        if (!props.gameState?.youDraw) return;
        if (!canvasRef.current) return;
        if (selectedTool === DrawingCanvasTool.Fill) return;
        const context = canvasRef.current.getContext("2d");

        let bounds = canvasRef.current.getBoundingClientRect();
        let mouseX = e.pageX - bounds.left - scrollX;
        let mouseY = e.pageY - bounds.top - scrollY;
        if (e.touches) {
            mouseX = e.touches[0].pageX - bounds.left - scrollX;
            mouseY = e.touches[0].pageY - bounds.top - scrollY;
        }
        mouseX /= bounds.width;
        mouseY /= bounds.height;
        mouseX *= canvasRef.current.width;
        mouseY *= canvasRef.current.height;

        context?.closePath();
        context?.moveTo(mouseX, mouseY);
        const drawEvent: DrawEvent = {
            type: 1,
            tool: selectedTool,
            color: color,
            size: size,
            x: mouseX,
            y: mouseY
        }
        props.sendDrawEvent(drawEvent);
        setIsDrawing(false);
    }

    const onMouseMove = (e: any) => {
        e.preventDefault();
        if (!isDrawing || !props.gameState?.youDraw) return;

        if (!canvasRef.current) return;
        const context = canvasRef.current.getContext("2d");
        if (!context) return;
        context.lineWidth = size;

        let bounds = canvasRef.current.getBoundingClientRect();
        let mouseX = e.pageX - bounds.left - scrollX;
        let mouseY = e.pageY - bounds.top - scrollY;
        if (e.touches) {
            mouseX = e.touches[0].pageX - bounds.left - scrollX;
            mouseY = e.touches[0].pageY - bounds.top - scrollY;
        }
        mouseX /= bounds.width;
        mouseY /= bounds.height;
        mouseX *= canvasRef.current.width;
        mouseY *= canvasRef.current.height;

        context?.lineTo(mouseX, mouseY);
        context?.stroke();
        const drawEvent: DrawEvent = {
            type: 2,
            tool: selectedTool,
            color: color,
            size: size,
            x: mouseX,
            y: mouseY
        }
        props.sendDrawEvent(drawEvent);
    }

    const onMouseEnter = (e: any) => {
        if (!isDrawing || !props.gameState?.youDraw) return;

        if (!canvasRef.current) return;
        const context = canvasRef.current.getContext("2d");
        if (!context) return;

        let bounds = canvasRef.current.getBoundingClientRect();
        let mouseX = e.pageX - bounds.left - scrollX;
        let mouseY = e.pageY - bounds.top - scrollY;
        if (e.touches) {
            mouseX = e.touches[0].pageX - bounds.left - scrollX;
            mouseY = e.touches[0].pageY - bounds.top - scrollY;
        }
        mouseX /= bounds.width;
        mouseY /= bounds.height;
        mouseX *= canvasRef.current.width;
        mouseY *= canvasRef.current.height;

        context.moveTo(mouseX, mouseY);
        context.beginPath();
        const drawEvent: DrawEvent = {
            type: 0,
            tool: selectedTool,
            color: color,
            size: size,
            x: mouseX,
            y: mouseY
        }
        props.sendDrawEvent(drawEvent);
    }

    const onMouseLeave = (e: any) => {
        if (!isDrawing || !props.gameState?.youDraw) return;

        if (!canvasRef.current) return;
        const context = canvasRef.current.getContext("2d");
        if (!context) return;
        context.closePath();

        let bounds = canvasRef.current.getBoundingClientRect();
        let mouseX = e.pageX - bounds.left - scrollX;
        let mouseY = e.pageY - bounds.top - scrollY;
        if (e.touches) {
            mouseX = e.touches[0].pageX - bounds.left - scrollX;
            mouseY = e.touches[0].pageY - bounds.top - scrollY;
        }
        mouseX /= bounds.width;
        mouseY /= bounds.height;
        mouseX *= canvasRef.current.width;
        mouseY *= canvasRef.current.height;

        const drawEvent: DrawEvent = {
            type: 1,
            tool: selectedTool,
            color: color,
            size: size,
            x: mouseX,
            y: mouseY
        }
        props.sendDrawEvent(drawEvent);
    }

    const clearCanvas = () => {
        if (!props.gameState?.youDraw) return;
        const drawEvent: DrawEvent = {
            type: 3,
            tool: selectedTool,
            color: color,
            size: size,
            x: 0,
            y: 0
        }
        props.sendDrawEvent(drawEvent);
        processEvent(drawEvent);
    }

    const updateColor = (colorIndex: number) => {
        if (!canvasRef.current) return;
        const context = canvasRef.current.getContext("2d");
        if (!context) return;
        context.strokeStyle = colors[colorIndex];
        setColor(colorIndex);
    }

    const setBrushMode = () => {
        if (!canvasRef.current) return;
        const context = canvasRef.current.getContext("2d");
        if (!context) return;

        context.globalCompositeOperation = 'source-over';
        setSelectedTool(DrawingCanvasTool.Brush);
    }

    const setEraserMode = () => {
        if (!canvasRef.current) return;
        const context = canvasRef.current.getContext("2d");
        if (!context) return;

        context.globalCompositeOperation = 'destination-out';
        setSelectedTool(DrawingCanvasTool.Eraser);
    }

    const setFillMode = () => {
        if (!canvasRef.current) return;
        const context = canvasRef.current.getContext("2d");
        if (!context) return;

        context.globalCompositeOperation = 'source-over';
        setSelectedTool(DrawingCanvasTool.Fill);
    }

    //TMP
    window.addEventListener("mouseup", disableDrawingWhenMouseUpOutsideOfCanvas.bind(this));
    document.addEventListener("mouseup", disableDrawingWhenMouseUpOutsideOfCanvas.bind(this));
    //TMP
    return (
        <div className={`${styles.canvasContainer} canvasContainer`}>
            <div className={styles.canvasParent}>
                <canvas
                    className={styles.canvas}
                    ref={canvasRef}
                    width={"728px"}
                    height={"600px"}
                    onMouseDown={onMouseDown}
                    onTouchStart={onMouseDown}
                    onMouseUp={onMouseUp}
                    onTouchEnd={onMouseUp}
                    onTouchEndCapture={onMouseUp}
                    onTouchCancel={onMouseUp}
                    onTouchCancelCapture={onMouseUp}
                    onMouseMove={onMouseMove}
                    onTouchMove={onMouseMove}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}>Your device or browser does not support canvas.</canvas>
                {props.gameState?.nextRoundStart != null && props.gameState?.word && <Results gameState={props.gameState} />}
            </div>

            {props.gameState?.youDraw && <>
                <div>
                    {colors.map((c, i) => <button key={c + i} className={`${color === i && styles.selected}`} onClick={() => updateColor(i)} style={{ backgroundColor: c, width: '32px', height: '32px' }}></button>)}
                </div>
                {/* <div className='brushSize'>
                    {sizes.map((s, i) => <button
                        key={s + i}
                        className={`${size === i && styles.selected}`}
                        onClick={() => setSize(i)}
                        style={{ width: '40px', height: '40px', margin: '2px 2px' }}>{s}</button>)}
                </div> */}
                <div className={styles.sliderContainer}>
                    <input
                        className={styles.slider}
                        type="range"
                        min="1"
                        max="25"
                        value={size}
                        id="brushSlider"
                        onChange={(e: any) => setSize(Number(e.target.value))}
                    />
                </div>
                <div>
                    <button className={`${selectedTool === DrawingCanvasTool.Brush && styles.selected}`} onClick={setBrushMode} style={{ margin: '2px' }}>
                        <img src='/images/pencil.png' alt='Pencil' width={"32px"} height={"32px"} />
                    </button>
                    <button className={`${selectedTool === DrawingCanvasTool.Eraser && styles.selected}`} onClick={setEraserMode} style={{ margin: '2px' }}>
                        <img src='/images/eraser.png' alt='Eraser' width={"32px"} height={"32px"} />
                    </button>
                    <button className={`${selectedTool === DrawingCanvasTool.Fill && styles.selected}`} onClick={setFillMode} style={{ margin: '2px' }}>
                        <img src='/images/fillBucket.png' alt='Fill Bucket' width={"32px"} height={"32px"} />
                    </button>
                    <button onClick={clearCanvas} style={{ margin: '2px' }}>Clear</button>
                </div>
            </>}
        </div>
    )
}
