import { useCountdown } from "../../hooks/useCountdown"

export const Countdown = (props: { date: string }) => {
    const secondsLeft = useCountdown(props.date);
    return (
        <h3>
            <p>{secondsLeft > 0 ? secondsLeft : 0}</p>
            <img src='/images/clock.png' alt='Timer Clock' width={"32px"} height={"32px"} />
        </h3>
    )
}